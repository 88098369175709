import { TechnicalError } from '@orus.eu/error'
import { NavbarBody, NavbarTitle, useAsyncCallback } from '@orus.eu/pharaoh'
import type { Permission } from '@orus.eu/right-access-management'
import { isSubsetOf } from '@orus.eu/sets'
import { useNavigate, useParams } from '@tanstack/react-router'
import { memo, type ReactNode } from 'react'
import { trpc } from '../../client'
import { useDisconnect } from '../../lib/hooks/use-disconnect'
import { useSession } from '../../lib/session'
import { usePermissions } from '../../lib/use-permissions'
import { InviteToLoginPage } from './invite-to-login/InviteToLoginPage'
import { MenuPage } from './menu-page'

const PartnerPage = memo<{
  children: ReactNode
  requiredPermissions: Permission[]
}>(function PartnerPage({ children, requiredPermissions }) {
  const session = useSession()
  const { organization } = useParams({ strict: false })
  const navigate = useNavigate()
  const disconnect = useDisconnect()
  const { permissions } = usePermissions()

  const createSubscription = useAsyncCallback(async () => {
    const subscriptionId = await trpc.subscriptions.createSubscription.mutate({
      organizationTechnicalName: organization,
    })
    if (!organization) throw new TechnicalError('The connected user is partner and does not have an organization')

    const route = {
      to: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
      params: { subscriptionId, organization },
    }

    void navigate(route)
  }, [navigate, organization])

  if (!session) return <InviteToLoginPage />

  // If the user is a client, they should not be able to access the backoffice
  if (session.permissions?.type === 'client') return <InviteToLoginPage />

  // If the user is a platform user, they can access the partner pages, with their platform perms applied
  if (
    session.permissions?.type === 'platform' &&
    isSubsetOf(
      new Set(requiredPermissions),
      new Set(session.permissions.rolesPermissions.flatMap((role) => role.permissions)),
    )
  )
    return <InviteToLoginPage />

  // If the user is a partner user, they can access the partner pages, with their partner perms applied
  if (session.permissions?.type === 'partner') {
    // ensures there's at least one organization for the member
    if (session.permissions.memberships.length === 0) return <InviteToLoginPage />
    // if the member cannot access to requested organization, redirect to the first one
    if (session.permissions.memberships.some(({ organization: { technicalName } }) => technicalName !== organization))
      void navigate({
        to: '/partner/$organization/home',
        params: { organization: session.permissions.memberships[0].organization.technicalName },
      })

    // checks that the user has the proper permissions
    if (
      !isSubsetOf(
        new Set(requiredPermissions),
        new Set(
          session.permissions.memberships
            .filter(({ organization: { technicalName } }) => organization === technicalName)
            .flatMap(({ rolesPermissions }) => rolesPermissions.flatMap(({ permissions }) => permissions)),
        ),
      )
    )
      return <InviteToLoginPage />
  }

  return (
    <MenuPage
      menuTitle={<NavbarTitle variant="partner" organization={organization!} />}
      menuBody={
        <NavbarBody
          variant="partner"
          organization={organization!}
          disconnect={disconnect}
          createSubscription={createSubscription}
          permissions={permissions}
        />
      }
      pageContent={children}
    />
  )
})
export default PartnerPage
